type ButtonProps = {
    children: React.ReactNode
    type?: 'button' | 'submit' | 'reset'
    kind?: 'primary' | 'secondary' | 'default' | 'tertiary'
    className?: string
    onClick?: () => void
    disabled?: boolean
}

export default function Button({ children, kind = 'default', className, onClick, disabled }: ButtonProps) {
    if (kind === 'tertiary') {
        return (
            <button className={` rounded-lg text-white bg-[#ce4f53] flex justify-center items-center ${className}`}
                onClick={onClick}
                disabled={disabled}
            >
                {children}
            </button>
        )
    }

    if (kind === 'secondary') {
        return (
            <button className={`bg-transparent rounded-lg text-primary border border-primary flex justify-center items-center ${className}`}
                onClick={onClick}
                disabled={disabled}
            >
                {children}
            </button>
        )
    }

    if (kind === 'primary') {
        return (
            <button className={`bg-primary flex justify-center items-center rounded-lg text-white ${className}`}
                onClick={onClick}
                disabled={disabled}
            >
                {children}
            </button>
        )
    }
    return (
        <button className={`flex justify-center items-center ${className}`}
            onClick={onClick}
            disabled={disabled}
        >
            {children}
        </button>
    )
}