import IonIcon from "@reacticons/ionicons";
import { NavLink } from "react-router-dom";

const dataNavLink = [
    {
        label: 'Ví tiền',
        href: '/payment',
        icon: 'wallet-outline'
    },
    {
        label: 'Trang chủ',
        href: '/',
        icon: 'home-outline'
    },
    {
        label: 'Hồ sơ',
        href: '/user/profile',
        icon: 'person-outline'
    },
]

export default function Navigation() {
    return (
        <div className='fixed bottom-0 left-0 w-full'>
            <nav className='flex justify-between items-center w-full px-4 sm:px-8 shadow-sd-primary bg-white m-auto max-w-screen-sm'>
                <div className="flex w-full items-center justify-between ">
                    {
                        dataNavLink.map((item, index) => {
                            return (
                                <NavLink to={item.href} key={index} className={(active) => `flex border-t-2 sm:border-none sm:px-3 py-2 px-8 flex-col items-center ${active.isActive ? 'border-primary text-primary' : 'border-transparent'}`}>
                                    <IonIcon name={item.icon as any} className='text-2xl  sm:text-black' />
                                    <span className="text-sm">{item.label}</span>
                                </NavLink>
                            )
                        })
                    }
                </div>
            </nav>
        </div>
    )
}