import logoCongThuong from "./imgs/congthuong.png";
import imgBg from "./imgs/backgroud1.jpg";
import Banner from "./imgs/1678799403_banner1.png";
import VayChap from "./imgs/1678799394_1667753756_vay-tin-chap.jpg";
import Card from "./imgs/1678799420_card.jpg";
import Default from "./imgs/default.png";
import Logo from "./imgs/Logo.png";
import NganHang from "./imgs/1678799411_1667753766_ngan-hang.jpg";
import Success from "./imgs/success.jpg";

const IMAGES = {
  logoCongThuong,
  imgBg,
  Banner,
  VayChap,
  Card,
  Default,
  NganHang,
  Logo,
  Success,
};

export default IMAGES;
