// Import the functions you need from the SDKs you need
import { initializeApp, getApp, getApps } from "firebase/app";
import { getAuth, GoogleAuthProvider } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

// const firebaseConfig = {
//   apiKey: "AIzaSyDzcNZjttI1p_BrhbHi_qo_H7bS3hr80Mw",
//   authDomain: "vaynhanh-f7b0c.firebaseapp.com",
//   projectId: "vaynhanh-f7b0c",
//   storageBucket: "vaynhanh-f7b0c.appspot.com",
//   messagingSenderId: "752846005310",
//   appId: "1:752846005310:web:a06bee5c72c78ee8845d6f",
//   measurementId: "G-ENWXRGM1HF",
// };

// const firebaseConfig = {
//   apiKey: "AIzaSyBERJer5OrC2qR14WKCPqnNoY0B4ECEV14",
//   authDomain: "shinhan-49fc3.firebaseapp.com",
//   databaseURL: "https://shinhan-49fc3-default-rtdb.firebaseio.com",
//   projectId: "shinhan-49fc3",
//   storageBucket: "shinhan-49fc3.appspot.com",
//   messagingSenderId: "343165078201",
//   appId: "1:343165078201:web:3a0414be6b93bdcde5a968",
//   measurementId: "G-VRQWNWQ6LF",
// };

const firebaseConfig = {
  apiKey: "AIzaSyCpdOmMdwntqF85inr6JqpJR65lM5NV2VI",
  authDomain: "adminvennus.firebaseapp.com",
  databaseURL:
    "https://adminvennus-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "adminvennus",
  storageBucket: "adminvennus.appspot.com",
  messagingSenderId: "249405445215",
  appId: "1:249405445215:web:bcb009c5522713c51677ea",
  measurementId: "G-YGNWHJX2B3",
};

// Initialize Firebase
const app = !getApps().length ? initializeApp(firebaseConfig) : getApp();

const auth = getAuth(app);

const provider = new GoogleAuthProvider();

const db = getFirestore();
const storage = getStorage();

export { auth, provider, db, storage };
