import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { addDoc, collection, doc, getDoc, setDoc, updateDoc } from "firebase/firestore";
import { deleteObject, getDownloadURL, ref, uploadBytes } from "firebase/storage";
import Cookies from 'js-cookie';
import { toast } from "react-toastify";
import { auth, db, storage } from "../../../configs/firebase.config";
import history from "../../store/history";
import { AuthState, InfoLoan } from "./auth.type";
import { v4 as uuidv4 } from 'uuid';



export const initialState: AuthState = {
    uid: '',
    user: null,
    isLoading: false,
    loan: null,

    infoBank: {
        name: '',
        numberCard: '',
        beneficiaryName: '',
    },
}


// REGISTER
export const authRegister = createAsyncThunk('auth/authRegister', async (payload: string) => {
    return payload
})

// AUTH LOGIN
export const authLogin = createAsyncThunk('auth/authLogin', async (payload: string) => {
    return payload
})

// LOAN FOR USER
export const loanForUser = createAsyncThunk('auth/loanForUser', async (payload: InfoLoan) => {
    return payload
})

// AUTH UPDATE IAMGE
export const authUpdateImage = createAsyncThunk(
    "auth/authUpdateImage",
    async (payload: {
        imageUrlFront: any;
        imageUrlBack: any;
        imageUrlFace: any;
    }) => {

        const uid = String(Cookies.get('accessToken'))

        if (!uid) {
            return
        }

        const userDocRef = doc(db, "users", uid);

        const dataUser = await getDoc(userDocRef)

        console.log(dataUser.data());

        const storageRef = ref(
            storage,
            `images/${uid}/${payload.imageUrlFront.name}`
        );
        await uploadBytes(storageRef, payload.imageUrlFront, {});
        const downloadURL = await getDownloadURL(storageRef);

        const storageRef2 = ref(
            storage,
            `images/${uid}/${payload.imageUrlBack.name}`
        );
        await uploadBytes(storageRef2, payload.imageUrlBack, {});
        const downloadURL2 = await getDownloadURL(storageRef2);

        const storageRef3 = ref(
            storage,
            `images/${uid}/${payload.imageUrlFace.name}`
        );
        await uploadBytes(storageRef3, payload.imageUrlFace, {});
        const downloadURL3 = await getDownloadURL(storageRef3);


        if (dataUser.data()?.image?.imageUrlFront && dataUser.data()?.image?.imageUrlBack && dataUser.data()?.image?.imageUrlFace) {
            const docSnap = await getDoc(userDocRef);

            if (docSnap.exists()) {
                const { imageUrlFront } = docSnap.data().image;
                const { imageUrlBack } = docSnap.data().image;
                const { imageUrlFace } = docSnap.data().image;

                const deleteRef = ref(storage, imageUrlFront);
                await deleteObject(deleteRef);

                const deleteRef2 = ref(storage, imageUrlBack);
                await deleteObject(deleteRef2);

                const deleteRef3 = ref(storage, imageUrlFace);
                await deleteObject(deleteRef3);

                await updateDoc(userDocRef, {
                    photoURL: downloadURL3,
                    image: {
                        imageUrlFront: downloadURL,
                        imageUrlBack: downloadURL2,
                        imageUrlFace: downloadURL3,
                    },
                });
            }
        } else {
            await updateDoc(userDocRef, {
                photoURL: downloadURL3,
                image: {
                    imageUrlFront: downloadURL,
                    imageUrlBack: downloadURL2,
                    imageUrlFace: downloadURL3,
                },
            });
        }
    }
);

// AUTH UPDATE INFO
export const authUpdateInfo = createAsyncThunk('auth/authUpdateInfo', async (payload: {
    address: string
    cmnd: string
    dateOfBirth: string
    fullName: string
    genre: string
    income: string
    job: string
    phoneNumber: string
    purpose: string
    relationship: string
}) => {
    if (payload.address === '' || payload.cmnd === '' || payload.dateOfBirth === '' || payload.fullName === '' || payload.genre === '' || payload.income === '' || payload.job === '' || payload.phoneNumber === '' || payload.purpose === '' || payload.relationship === '') {
        return
    } else {
        const uid = String(Cookies.get('accessToken'))

        if (uid) {
            const userDocRef = doc(db, "users", uid);

            const docSnap = await getDoc(userDocRef);

            if (!docSnap.exists()) {
                // Chưa có doc, tạo mới
                await setDoc(userDocRef, {
                    uid: uid,
                    address: payload.address,
                    cmnd: payload.cmnd,
                    dateOfBirth: payload.dateOfBirth,
                    fullName: payload.fullName,
                    genre: payload.genre,
                    income: payload.income,
                    job: payload.job,
                    phoneNumber: payload.phoneNumber,
                    purpose: payload.purpose,
                    relationship: payload.relationship,
                });
            } else {
                // Đã có doc, update
                await updateDoc(userDocRef, {
                    address: payload.address,
                    cmnd: payload.cmnd,
                    dateOfBirth: payload.dateOfBirth,
                    fullName: payload.fullName,
                    genre: payload.genre,
                    income: payload.income,
                    job: payload.job,
                    phoneNumber: payload.phoneNumber,
                    purpose: payload.purpose,
                    relationship: payload.relationship,
                });
            }

            return {
                address: payload.address,
                cmnd: payload.cmnd,
                dateOfBirth: payload.dateOfBirth,
                fullName: payload.fullName,
                genre: payload.genre,
                income: payload.income,
                job: payload.job,
                phoneNumber: payload.phoneNumber,
                purpose: payload.purpose,
                relationship: payload.relationship,
            }
        }
    }

})

// AUTH UPDATE INFO BANKING
export const authUpdateInfoBanking = createAsyncThunk('auth/authUpdateInfoBanking', async (payload: {
    numberBank: string,
    nameBank: string,
    nameAccount: string,
}) => {

    if (payload.numberBank === '' || payload.nameBank === '' || payload.nameAccount === '') {
        return
    } else {
        const uid = String(Cookies.get('accessToken'))

        if (uid) {
            const userDocRef = doc(db, "users", uid);

            const docSnap = await getDoc(userDocRef);

            if (!docSnap.exists()) {
                // Chưa có doc, tạo mới
                await setDoc(userDocRef, {
                    uid: uid,
                    numberBank: payload.numberBank,
                    nameBank: payload.nameBank,
                    nameAccount: payload.nameAccount,
                });
            } else {
                // Đã có doc, update
                await updateDoc(userDocRef, {
                    numberBank: payload.numberBank,
                    nameBank: payload.nameBank,
                    nameAccount: payload.nameAccount,
                });
            }

            return {
                numberBank: payload.numberBank,
                nameBank: payload.nameBank,
                nameAccount: payload.nameAccount,
            }
        }
    }
})

// AUTH UPDATE SIGNATURE
export const authUpdateSsignature = createAsyncThunk('auth/handleUpdateSsignature', async (payload: any) => {
    const uid = String(Cookies.get('accessToken'))

    if (uid) {
        const userDocRef = doc(db, "users", uid);

        const docSnap = await getDoc(userDocRef);

        if (!docSnap.exists()) {
            // Chưa có doc, tạo mới
            await setDoc(userDocRef, {
                uid: uid,
                signature: payload,
            });
        } else {
            // Đã có doc, update
            await updateDoc(userDocRef, {
                signature: payload,
            });
        }

        return payload
    }
})

// AUTH CONFIRM LOAN
export const confirmLoan = createAsyncThunk('auth/confirmLoan', async (payload: any) => {
    const uid = String(Cookies.get('accessToken'))

    if (uid) {
        const collectionRef = collection(db, "loan-list");

        await addDoc(collectionRef, {
            id: uuidv4(),
            ...payload,
        });

        return {
            id: uuidv4(),
            ...payload,
        }
    } else {
        return
    }
})

const reducer = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        handleLogout: (state) => {
            auth.signOut()
            state.user = null
            state.uid = ''
            state.loan = null
            Cookies.remove('accessToken')
            localStorage.removeItem('user')
            history.push('/login')
        }
    },
    extraReducers: (builder) => {
        // REGISTER
        builder.addCase(authRegister.fulfilled, (state, action) => {
            state.uid = action.payload
            toast.success('Đăng ký thành công')
            window.location.href = '/'
        })

        // Login
        builder.addCase(authLogin.fulfilled, (state, action) => {
            state.uid = action.payload
            toast.success('Đăng nhập thành công')
            window.location.href = '/'
        })

        // Loan for user
        builder.addCase(loanForUser.fulfilled, (state, action) => {
            console.log(action.payload);
            state.loan = {
                ...state.loan,
                loanAmount: action.payload.loanAmount,
                loanInterestRate: action.payload.loanInterestRate,
                loanMonth: action.payload.loanMonth,
                uid: action.payload.uid,
            }

        })

        // Update image
        builder.addCase(authUpdateImage.pending, (state) => {
            state.isLoading = true
        })
        builder.addCase(authUpdateImage.rejected, (state) => {
            state.isLoading = false
            toast.error('Cập nhật ảnh thất bại')
        })
        builder.addCase(authUpdateImage.fulfilled, (state: any, action: any) => {
            toast.success('Cập nhật ảnh thành công')
            state.isLoading = false
            history.push('/user/infomation-confirm')
        })

        // Update info
        builder.addCase(authUpdateInfo.pending, (state) => {
            state.isLoading = true
        })
        builder.addCase(authUpdateInfo.rejected, (state) => {
            state.isLoading = false
            toast.error('Cập nhật thông tin thất bại')
        })
        builder.addCase(authUpdateInfo.fulfilled, (state: any, action: any) => {
            if (action.payload) {
                state.isLoading = false
                toast.success('Cập nhật thông tin thành công')
                history.push('/user/payment-confirm')
            } else {
                toast.error('Vui lòng điền đủ thông tin')
                state.isLoading = false
            }
        })

        // Update info banking
        builder.addCase(authUpdateInfoBanking.pending, (state) => {
            state.isLoading = true
        })
        builder.addCase(authUpdateInfoBanking.rejected, (state) => {
            state.isLoading = false
            toast.error('Cập nhật thông tin thất bại')
        })
        builder.addCase(authUpdateInfoBanking.fulfilled, (state: any, action: any) => {
            if (action.payload) {
                state.isLoading = false
                toast.success('Cập nhật thông tin thành công')
                history.push('/payment/debit-confirm')
            } else {
                toast.error('Vui lòng điền đủ thông tin')
                state.isLoading = false
            }
        })

        // Update signature
        builder.addCase(authUpdateSsignature.pending, (state) => {
            state.isLoading = true
        })
        builder.addCase(authUpdateSsignature.rejected, (state) => {
            state.isLoading = false
            toast.error('Cập nhật chữ ký thất bại')
        })
        builder.addCase(authUpdateSsignature.fulfilled, (state: any, action: any) => {
            state.isLoading = false
            console.log('Kí thành công');
        })

        // Confirm loan
        builder.addCase(confirmLoan.pending, (state) => {
            state.isLoading = true
        })
        builder.addCase(confirmLoan.rejected, (state) => {
            state.isLoading = false
            toast.error('Xác nhận khoản vay thất bại')
        })
        builder.addCase(confirmLoan.fulfilled, (state: any, action: any) => {
            state.isLoading = false
            toast.success('Xác nhận khoản vay thành công')
            history.push('/success-loan')
        })

    }
})

export const AuthAction = reducer.actions
export const AuthReducer = reducer.reducer
