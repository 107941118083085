import { useEffect, useState } from 'react'

export default function Account() {

    const [account, setAccount] = useState<any>(null)

    const phoneNumber = `0${account?.phoneNumber?.slice(3)}`

    useEffect(() => {
        localStorage.getItem('user') && setAccount(JSON.parse(localStorage.getItem('user') || '{}'))
    }, [])

    return (
        <div className='font-semibold flex flex-col'>
            <span>Xin chào,</span>
            <span>{account?.myPhoneNumber ? account?.myPhoneNumber : '-----------------'}</span>
        </div>
    )
}