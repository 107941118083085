import IonIcon from "@reacticons/ionicons";
import Button from "../../../../../components/Button";
import { Link } from "react-router-dom";

export default function Notification() {
    return (
        <Link to={'/user/notify'} className="flex justify-center items-center" >
            <Button className="hover:bg-white px-2 rounded-lg duration-300">
                <IonIcon name='notifications' className="text-3xl" />
            </Button>
        </Link>
    )
}