import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { collection, doc, getDoc, getDocs } from "firebase/firestore";
import { db } from "../../../configs/firebase.config";

type RemindState = {
    remind: any
    statusBalance: any
    user: any
    drawer: any
}

export const initialState: RemindState = {
    remind: [],
    statusBalance: null,
    user: null,
    drawer: null
}

export const getFormRemind = createAsyncThunk('remind/getFormRemind', async () => {
    const querySnapshot = await getDocs(collection(db, "remind"));
    const formRemindData: any[] = [];

    querySnapshot.forEach((doc) => {
        formRemindData.push(doc.data().formRemind);
    });

    return formRemindData;
});

export const getBalance = createAsyncThunk('remind/getBalance', async (payload: any) => {
    const docRef = doc(db, "balance", payload);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
        return docSnap.data()?.withdrawalStatus
    } else {
        console.log("No such document!");
    }
})

export const getUser = createAsyncThunk('remind/getUser', async (payload: any) => {
    const docRef = doc(db, "users", payload);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
        return docSnap.data()
    } else {
        console.log("No such document!");
    }
})


export const getWithDrawer = createAsyncThunk('remind/getWithDrawer', async (payload: any) => {
    const contractRef = collection(db, "withdraw-money");
    const querySnapshot = await getDocs(contractRef);

    const contract: any[] = [];

    querySnapshot.forEach((doc) => {
        if (doc.data()?.uid === payload) {
            contract.push(doc.data());
        }
    });

    console.log(contract);


    const earliestItem = contract.reduce((prev, curr) => {
        return prev.time > curr.time ? prev : curr;
    });

    return earliestItem
})



const reducer = createSlice({
    name: 'remind',
    initialState,
    reducers: {

    },
    extraReducers: (builder) => {
        builder.addCase(getFormRemind.fulfilled, (state, action) => {
            state.remind = action.payload
        })

        builder.addCase(getBalance.fulfilled, (state, action) => {
            state.statusBalance = action.payload
        })

        builder.addCase(getUser.fulfilled, (state, action) => {
            state.user = action.payload
        })

        builder.addCase(getWithDrawer.fulfilled, (state, action) => {
            state.drawer = action.payload
        })
    }
})

export const RemindAction = reducer.actions
export const RemindReducer = reducer.reducer
