import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Account from "./components/Account";
import Notification from "./components/Notification";
import BackPage from "../../../components/BackPage";

const dataNavLink = [
    {
        label: '',
        kind: 'primary',
        keyword: null,
    },
    {
        label: 'Ví tiền',
        kind: 'secondary',
        keyword: 'payment',
    },
    {
        label: 'Lịch sử giao dịch',
        kind: 'tertiary',
        keyword: 'history',
    },
    {
        label: 'Hồ sơ',
        kind: 'secondary',
        keyword: 'profile',
    },
    {
        label: 'Thông báo',
        kind: 'tertiary',
        keyword: 'notify',
    }
    ,
    {
        label: 'Xác minh',
        kind: 'tertiary',
        keyword: 'accuracy',
    }
    ,
    {
        label: 'Chọn khoản vay',
        kind: 'tertiary',
        keyword: 'debit',
    },
    {
        label: 'Xác minh',
        kind: 'tertiary',
        keyword: 'infomation-confirm',
    },
    {
        label: 'Xác minh',
        kind: 'tertiary',
        keyword: 'payment-confirm',
    },
    {
        label: 'Xác nhận vay',
        kind: 'tertiary',
        keyword: 'debit-confirm',
    },
    {
        label: 'Xác nhận vay',
        kind: 'tertiary',
        keyword: 'success-loan',
    },
    {
        label: 'Thông tin cá nhân  ',
        kind: 'tertiary',
        keyword: 'detail',
    }
]

export default function Header() {

    const [isShowTitlePage, setIsShowTitlePage] = useState<string | null>(null);
    const [isLayout, setIsLayout] = useState<string>('primary');

    const location = useLocation();

    const pathnames = location.pathname.split('/');

    const lastSegment = pathnames[pathnames.length - 1];

    useEffect(() => {
        if (lastSegment) {
            dataNavLink.map((item) => {
                if (item.keyword === lastSegment) {
                    setIsShowTitlePage(item.label)
                    setIsLayout(item.kind)
                }
            })
        } else {
            setIsShowTitlePage('')
            setIsLayout('primary')
        }
    }, [lastSegment])

    return (
        <header className='bg-primary sticky top-0 z-50 flex justify-between items-center px-4 py-3 text-white'>
            {
                isLayout === 'primary' ? (
                    <>
                        {
                            !isShowTitlePage && <Account />
                        }
                        <Notification />
                    </>
                ) : isLayout === 'secondary' ? (
                    <>
                        <div></div>
                        <p className="absolute left-1/2 -translate-x-1/2 text-xl font-bold">{isShowTitlePage}</p>
                        <Notification />
                    </>
                ) : (
                    <>
                        <BackPage />
                        <p className="absolute left-1/2 -translate-x-1/2 text-xl font-bold">{isShowTitlePage}</p>
                        <div></div>
                    </>
                )
            }
        </header>
    )
}