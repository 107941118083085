import React from "react";
import { Route } from "../configs";
import AuthLayout from "../layouts/AuthLayout";
import MainLayout from "../layouts/MainLayout";

const routes: Route[] = [
  // AUTH
  {
    path: "/login",
    component: React.lazy(() => import("./Auth/Login")),
    layout: AuthLayout,
    private: false,
  },
  {
    path: "/register",
    component: React.lazy(() => import("./Auth/Register")),
    layout: AuthLayout,
    private: false,
  },

  // MAIN
  {
    path: "/",
    component: React.lazy(() => import("./Home")),
    layout: MainLayout,
    private: true,
  },
  // PAYMENT
  {
    path: "/payment",
    component: React.lazy(() => import("./Payment")),
    layout: MainLayout,
    private: true,
  },
  {
    path: "/payment/history",
    component: React.lazy(() => import("./Payment/History")),
    private: true,
    layout: MainLayout,
  },
  {
    path: "/payment/debit",
    component: React.lazy(() => import("./Payment/Debit")),
    private: true,
    layout: MainLayout,
  },
  {
    path: "/payment/debit-confirm",
    component: React.lazy(() => import("./Payment/Debit/ConfirmDebit")),
    private: true,
    layout: MainLayout,
  },
  {
    path: "/payment/contract",
    component: React.lazy(() => import("./Payment/Contract")),
    private: true,
    layout: MainLayout,
  },
  {
    path: "/success-loan",
    component: React.lazy(() => import("./Payment/Debit/SuccessDebit")),
    private: true,
    layout: MainLayout,
  },
  // USER
  {
    path: "/user/profile",
    component: React.lazy(() => import("./Profile")),
    private: true,
    layout: MainLayout,
  },
  {
    path: "/user/detail",
    component: React.lazy(() => import("./Profile/Detail")),
    private: true,
    layout: MainLayout,
  },
  {
    path: "/user/infomation-confirm",
    component: React.lazy(() => import("./Profile/Infomation/ConfirmInfo")),
    private: true,
    layout: MainLayout,
  },
  // ACCURACY
  {
    path: "/user/accuracy",
    component: React.lazy(() => import("./Accuracy")),
    private: true,
    layout: MainLayout,
  },
  {
    path: "/user/payment-confirm",
    component: React.lazy(() => import("./Payment/PaymentConfirm")),
    private: true,
    layout: MainLayout,
  },
  {
    path: "/user/notify",
    component: React.lazy(() => import("./Profile/Notify")),
    private: true,
    layout: MainLayout,
  },
];

export default routes;
