import Cookies from 'js-cookie'
import React, { useEffect } from 'react'
import IMAGES from '../../assets'
import Header from '../components/Header'
import Navigation from '../components/Navigation'
import history from '../../redux/store/history'

type MainLayoutProps = {
    children: React.ReactNode
}

export default function MainLayout({ children }: MainLayoutProps) {

    const accessToken = Cookies.get('accessToken')

    useEffect(() => {
        if (!accessToken) {
            history.push('/login')
        }
    }, [accessToken])

    return (
        <div className='min-h-screen sm:overflow-hidden w-full relative'>
            <img src={IMAGES.imgBg} alt="" className='w-full top-0 left-0 h-full absolute object-cover' />
            <div className='absolute top-0 left-0 w-full h-full bg-[#ffffffbd]'></div>
            <div className=' w-full h-full'>
                <div className='max-w-screen-sm m-auto bg-white h-full relative' style={{
                    overflow: "auto"
                }}>
                    <Header />
                    <main className='min-h-screen'>
                        {children}
                    </main>
                    <Navigation />
                </div>
            </div>
        </div>
    )
}