import history from '../../redux/store/history';
import Button from '../Button'
import IonIcon from '@reacticons/ionicons'

export default function BackPage() {

    return (
        <Button
            onClick={() => history.back()}
        >
            <IonIcon name='chevron-back' className='text-2xl text-white' />
        </Button>
    )
}
